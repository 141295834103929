import React from 'react';
import styled, { css } from 'styled-components';
import { Flex } from 'rebass';
import { Icon } from '../atoms';
import { media } from '../../utils/mediaBreakpoints';

const FlexExtended = styled(Flex).attrs(({ count }) => ({ count }))`
  position: relative;
  ${({ count }) => count && css`
    &:after {
      position: absolute;
      content: '${({ count }) => count}';
      width: ${props => props.countWidth};
      height: ${props => props.countWidth};
      border-radius: ${props => props.borderRadius};
      text-align: center;
      background-color: ${props => ({ theme: { colors }}) => colors[props.iconBackgroundColor]};
      color: ${({ theme: { colors }}) => colors.white};
      font-size: ${({ theme: { fontSizes }}) => fontSizes.sm};
      font-family: ${({ theme: { fonts }}) => fonts.semiBold};
      left: ${props => props.left};
      bottom: ${props => props.bottom};
      display: flex;
      align-items: center;
      justify-content: center;
      ${media.mobile`
        left: 15px;
      `}
    }
  `}
`

const IconWithNotificationsCount = ({ src, className, size, count, ...props }) => {
  return(
    <FlexExtended className={className} count={count} {...props}>
      <Icon src={src} size={size} pointer={'pointer'}/>
    </FlexExtended>
  )
}

IconWithNotificationsCount.defaultProps = {
  iconBackgroundColor: 'darkRed',
  countWidth: '20px',
  borderRadius: '40%',
  fontSize: 'sm',
  left: '5px',
  bottom: '15px'

}

export default IconWithNotificationsCount;