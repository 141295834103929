import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Card, Button, Icon } from '../../atoms'
import { Text } from '../../atoms/typography'
import { pointRedeemedPopup, close } from '../../../assets/icons'
import { REWARDS, HISTORY } from '../../../routes/private'
import tracker from '../../../utils/tracker'
import { Modal } from '../../molecules'

const ModalContainer = styled(Card)`
  position: relative;
  width: 450px;
  height: 100%;
  border-radius: 15px;
`

const CloseIcon = styled.div`
  width: 32px;
  height: 32px;
  display: grid;
  place-items: center;
  position: absolute;
  top: -15px;
  right: 5%;
  background-color: ${p => p.theme.colors.backgroundColor};
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  filter: drop-shadow(0px 2px 2px #8888);
`

const ExtendedCard = styled(Card)`
  background: url(${pointRedeemedPopup}) no-repeat;
  background-size: contain;
`

const ExtendedButton = styled(Button)`
  padding: 16px 32px;
  border-radius: 5px;
  line-height: 1;
  width: 200px;
  height: 48px;
  position: relative;
  transition: all .2s;
  
  &::before {
   content: '';
   display: inline-block;
   position: absolute;
   border-radius: 5px;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: ${p => p.theme.colors.primary};
   padding: 0;
   z-index: -1;
   transition: all .4s;
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0px 2px 2px #888888;
    &::before {
        transform: scaleX(1.4) scaleY(1.6);
        opacity: 0;
    }
  }
  
  &:active {
    transform: translateY(-1px);
    box-shadow: 0px 2px 2px #888888;
  }
`

const PointsEarnedModal = ({data, onCloseModal}) => {
  const history = useHistory()
  
  return (
    <Modal size='xxs' closeModal={onCloseModal}>
      <ModalContainer>
        <CloseIcon onClick={onCloseModal}>
          <Icon src={close} size='md'/>
        </CloseIcon>
        <ExtendedCard alignItems='center' borderRadius='15px' borderRequired={false}>
          <Text color='extraInformation' fontSize='xl' lineHeight='24px' mb='4px' mt='36%' bolder>{data?.points}</Text>
          <Text color='subtitle' fontSize='sm' lineHeight='12px' letterSpacing='1.2px' bold>H-POINTS EARNED</Text>
          <Text color='extraInformation' lineHeight='24px' mt='24px' bold>Congratulations!!</Text>
          <Text color='subtitle' lineHeight='24px' px='75px' mt='2px'>{data?.message}</Text>
          <ExtendedButton 
            type='primary' 
            mt='12px' 
            mb='20px' 
            onClick={ 
              () => {
                tracker.track('rp_points_earned',{screen: 'rewards', action: 'close'})
                history.push(REWARDS+HISTORY)
                onCloseModal()
              }}
          >VIEW EARNINGS</ExtendedButton>
        </ExtendedCard>
      </ModalContainer>
    </Modal>
  )
}

export default PointsEarnedModal
